import React, { useEffect, useState } from "react";
import "./CountDown.css";

const formatNumber = (number) => {
    return number.toString().padStart(2, '0');
};

const calculateTimeLeft = (endDate) => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    } else {
        timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }

    return timeLeft;
};

const CountdownTimer = ({ endDate }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(endDate));
        }, 1000);

        return () => clearInterval(timer);
    }, [endDate]);

    return (
        <div className="countdown-timer">
            <div className="time-unit">
                <div className="val">{formatNumber(timeLeft.days)}</div>
                <span className="label">Tage</span>
            </div>
            <div className="time-unit">
                <div className="val">{formatNumber(timeLeft.hours)}</div>
                <span className="label">Stunden</span>
            </div>
            <div className="time-unit">
                <div className="val">{formatNumber(timeLeft.minutes)}</div>
                <span className="label">Minuten</span>
            </div>
            <div className="time-unit">
                <div className="val">{formatNumber(timeLeft.seconds)}</div>
                <span className="label">Sekunden</span>
            </div>
        </div>
    );
};

export default CountdownTimer;
