import React from "react";
import "./SidePanel.css";

const SidePanel = ({ isOpen, togglePanel }) => {
    return (
        <div className={`side-panel ${isOpen ? "open" : ""}`}>
            <button className="btn-trans close-panel-btn" onClick={togglePanel}>
                &times;
            </button>
            <div className="panel-content">
                <h2>Willkommen bei creative mediadesign – Ihre Experten für maßgeschneiderte Softwarelösungen!</h2>
                <p>Nach einer längeren Phase der Erholung und strategischen Neuausrichtung freuen wir uns, Ihnen mitteilen zu dürfen, dass wir von <strong>creative mediadesign</strong> zurück sind – bereit, Ihnen zu helfen, Ihre digitalen Ziele zu erreichen! Als Internetagentur mit langjähriger Erfahrung und frischem Elan stehen wir Ihnen nun wieder mit einem erweiterten Leistungsspektrum zur Seite, das speziell auf moderne Webanwendungen und Schnittstellen optimiert ist.</p>

                <p>In der dynamischen Welt des Internets ist es entscheidend, stets am Puls der Zeit zu bleiben. Genau aus diesem Grund haben wir unsere Expertise in den führenden Technologien der Webentwicklung ausgebaut.</p>

                <h3>Sie wollen Großes erreichen?</h3>

                <p>Sie haben eine innovative Idee oder ein anspruchsvolles Projekt, das auf die nächste Stufe gehoben werden soll?
                    Als spezialisierter Software-Dienstleister mit langjähriger Erfahrung in der Umsetzung maßgeschneiderter Lösungen
                    unterstützen wir Sie gerne dabei, Ihre Vision Wirklichkeit werden zu lassen.</p>

                <p>Da wir großen Wert auf die Qualität und Exklusivität unserer Dienstleistungen legen, bieten wir ausgewählten Partnern
                    die Möglichkeit, sich für einen begrenzten Zeitslot in unserem Projektkalender zu bewerben. Dies garantiert Ihnen unsere
                    volle Aufmerksamkeit und unser Engagement für den Erfolg Ihres Projekts.</p>

                <p>Nutzen Sie die untenstehende Kontaktmöglichkeiten, um sich für einen Zeitslot zu bewerben. Beschreiben Sie kurz Ihre Idee
                    oder Ihr Projekt und lassen Sie uns wissen, wie wir Ihnen helfen können. Wir werden uns zeitnah mit Ihnen in Verbindung
                    setzen, um die Details zu besprechen und den nächsten Schritt in die Wege zu leiten.</p>

                <div>
                    <p>
                        <strong>creative mediadesign</strong><br/>
                        Kirchweg 6<br/>
                        86674 Baar (Schwaben)<br/>
                        Deutschland
                    </p>
                    <p>
                        Fon: 08276 5187620
                    </p>
                    <p>
                        Mail: willkommen[a]creative-mediadesign.de<br/>
                        Web: www.creative-mediadesign.de
                    </p>
                </div>
            </div>
        </div>

    );
};

export default SidePanel;
