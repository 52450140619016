import React from 'react';

function Impressum({ onClose }) {
    return (
        <div className="overlay">
            <div className="overlay-content">
                <button className="btn-trans" onClick={onClose}>&times;</button>
                <h1>Impressum</h1>
                <p>
                    <strong>creative mediadesign</strong><br/>
                    Kirchweg 6<br/>
                    86674 Baar (Schwaben)<br/>
                    Deutschland
                </p>
                <p>
                    Fon: 08276 5187620<br/>
                    Fax: 08276 5187623
                </p>
                <p>
                    Mail: willkommen[a]creative-mediadesign.de<br/>
                    Web: www.creative-mediadesign.de
                </p>
                <p>
                    <strong>Verantwortlich für diese Webseite</strong><br/>
                    Markus Pasaric, Kirchweg 6, 86674 Baar
                </p>
                <p>
                    <strong>Umsatzsteuer-Identifikationsnummer</strong><br/>
                    DE269689859
                </p>
                <h2>
                    Online-Streitbeilegung
                </h2>
                <p>
                    Die Europäische Kommission stellt unter ec.europa.eu/consumers/odr/ eine Plattform zur Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer Streitigkeit nutzen können und auf der weitere Informationen zum Thema Streitschlichtung zu finden sind.
                </p>
                <h2>
                    Außergerichtliche Streitbeilegung
                </h2>
                <p>
                    Wir sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit mit einem Verbraucher an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </p>
                <h2>
                    Copyright
                </h2>
                <p>
                    Alle Rechte vorbehalten. Alle Daten dieser Webseite sind urheberrechtlich geschützt. Jede Bearbeitung, Vervielfältigung, Verbreitung und/oder öffentliche Wiedergabe, unabhängig vom verwendeten Medium, stellt einen Urheberrechtsverstoß dar.
                </p>
                <p>
                    Sämtliche Layouts, Bilder und Gestaltungselemente auf dieser Website, sowie redaktionelle Artikel und Inhalte sind urheberlich geschützt - eine Weiterverarbeitung, insbesondere Kopie von HTML-Seiten, Verfremdung sowie Screenshots ist nur zu privaten Zwecken gestattet. Eine weitere Nutzung ist nur mit schriftlicher Zustimmung von creative mediadesign gestattet.
                </p>
                <h2>
                    Haftungsausschluss
                </h2>
                <p>
                    Obwohl alle Inhalte sorgfältig geprüft und ständig aktualisiert werden, kann eine Garantie für die Vollständigkeit, Richtigkeit und letzte Aktualität nicht übernommen werden.
                </p>
                <p>
                    Trotz sorgfältiger inhaltlicher Kontrolle übernimmt creative mediadesign keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                </p>
                <h2>
                    Datenerhebung, -verarbeitung, -nutzung unserer Daten für kommerzielle Zwecke
                </h2>
                <p>
                    Gemäß § 28 BDSG widerspricht creative mediadesign jeder kommerziellen Verwendung und Weitergabe unserer Daten sowie der Verwendung personenbezogener Daten bei allen Direktmarketingunternehmen und in allen Werbeaussendungen.
                </p>
                <p>
                    Zusätzlich untersagen wir die Nutzung oder Übermittlung unserer Daten für Zwecke der Werbung und/oder der Markt- oder Meinungsforschung.
                </p>
                <h2>
                    Hinweise zu geschützen Markennamen
                </h2>
                <p>
                    Die Verwendung von geschützten Markennamen, Handelsnamen, Gebrauchsmuster und Markenlogos auf dieser Homepage stellt keine Urheberrechts-Verletzung dar, sondern dient als illustrativer Hinweis. Auch wenn diese an den jeweiligen Stellen nicht als solche gekennzeichnet sind, gelten die entsprechenden gesetzlichen Bestimmungen.
                </p>
                <p>
                    Die verwendeten Markennamen und -logos sind Eigentum der Hersteller und unterliegen deren Copyright Bestimmungen. Informationen hierzu entnehmen Sie bitte den Hinweisen der Hersteller auf deren Webseiten.
                </p>
                
                <button className="btn-trans" onClick={onClose}>&times;</button>
            </div>
        </div>
);
}

export default Impressum;
