import React, { useEffect } from 'react';
import 'particles.js/particles';

const particlesJS = window.particlesJS;

const ParticlesBackground = () => {
    useEffect(() => {
        // Konfiguration für particles.js
        particlesJS('particles-js', {
            particles: {
                number: {
                    value: 100,
                    density: {
                        enable: true,
                        value_area: 1500,
                    },
                },
                color: {
                    value: '#0582b7',
                },
                shape: {
                    type: 'circle',
                    stroke: {
                        width: 0,
                        color: '#0582b7',
                    },
                    polygon: {
                        nb_sides: 5,
                    },
                    image: {
                        src: 'img/github.svg',
                        width: 100,
                        height: 100,
                    },
                },
                opacity: {
                    value: 1,
                    random: true,
                    anim: {
                        enable: false,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false,
                    },
                },
                size: {
                    value: 10,
                    random: true,
                    anim: {
                        enable: true,
                        speed: 5,
                        size_min: 0.1,
                        sync: false,
                    },
                },
                line_linked: {
                    enable: true,
                    distance: 200,
                    color: '#0582b7',
                    opacity: 1,
                    width: 2,
                },
                move: {
                    enable: true,
                    speed: 6,
                    direction: 'none',
                    random: false,
                    straight: false,
                    out_mode: 'out',
                    bounce: false,
                    attract: {
                        enable: true,
                        rotateX: 600,
                        rotateY: 1200,
                    },
                },
            },
            interactivity: {
                detect_on: 'canvas',
                events: {
                    onhover: {
                        enable: true,
                        mode: 'grab',
                    },
                    onclick: {
                        enable: false,
                        mode: 'repulse',
                    },
                    resize: true,
                },
                modes: {
                    grab: {
                        distance: 400,
                        line_linked: {
                            opacity: 1,
                        },
                    },
                    bubble: {
                        distance: 400,
                        size: 40,
                        duration: 2,
                        opacity: 8,
                        speed: 3,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    push: {
                        particles_nb: 4,
                    },
                    remove: {
                        particles_nb: 2,
                    },
                },
            },
            retina_detect: true,
        });

        // Cleanup particles.js instance when the component unmounts
        return () => {
            window.pJSDom = [];
        };
    }, []);

    return <div id="particles-js" style={{ position: 'absolute', width: '100%', height: '100%' }}></div>;
};

export default ParticlesBackground;
