import React, { useState }  from "react";
import './App.css';
import CountdownTimer from "./components/CountDown";
import SidePanel  from "./components/SidePanel";
import ParticlesBackground from './components/ParticleBackground';
import Datenschutz from './components/Datenschutz';
import Impressum from './components/Impressum';
import './media.css';

function App() {
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    const [showDatenschutz, setShowDatenschutz] = useState(false);
    const [showImpressum, setShowImpressum] = useState(false);

    const handleOpenDatenschutz = () => setShowDatenschutz(true);
    const handleCloseDatenschutz = () => setShowDatenschutz(false);

    const handleOpenImpressum = () => setShowImpressum(true);
    const handleCloseImpressum = () => setShowImpressum(false);

  return (
    <div className="App pageWrapper">
        <ParticlesBackground />

        <div className="contentWrapper">
            <div className="logo">
                <img src="/logo.webp" alt="Logo" />
            </div>
            <CountdownTimer endDate="2024-11-01T11:00:00" />

            <h1>creative mediadesign ist zurück und präsentiert<br/>sich bald mit brandneuem Internetauftritt!</h1>
            <button className="open-panel-btn" onClick={togglePanel}>Kontakt aufnehmen</button>
            <SidePanel isOpen={isPanelOpen} togglePanel={togglePanel} />

            <div className="footer">
                <div className="social-media">
                    <a href="https://www.facebook.com/cr8tivemediadesign/" target="_blank" rel="noopener noreferrer">Facebook</a>
                    <a href="https://www.tiktok.com/@cr8tive_mediadesi/" target="_blank" rel="noopener noreferrer">TikTok</a>
                    <a href="https://www.instagram.com/cr8tive_mediadesign/" target="_blank" rel="noopener noreferrer">Instagram</a>
                </div>
            </div>

            <nav className="meta-nav">
                <button onClick={handleOpenDatenschutz}>Datenschutz</button>
                <button onClick={handleOpenImpressum}>Impressum</button>
            </nav>

            {showDatenschutz && <Datenschutz onClose={handleCloseDatenschutz} />}
            {showImpressum && <Impressum onClose={handleCloseImpressum} />}
        </div>
    </div>
  );
}
export default App;